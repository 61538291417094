<template>
	<div class="performance performance--overview">
		<number-circles :groups="groups" />
	</div>
</template>

<script>
import NumberCircles from '@/components/NumberCircles'

export default {
	name: 'AlternativeInvestment3',
	components: {
		NumberCircles,
	},
	data: () => ({
		groups: [
			{
				title: '2020',
				numbers: [
					{
						label: 'clients',
						value: 1453,
					},
					{
						label: 'unique funds',
						value: 6503,
					},
				],
			},
			{
				title: '2019',
				numbers: [
					{
						label: 'clients',
						value: 1208,
					},
					{
						label: 'unique funds',
						value: 5554,
					},
				],
			},
		]
	}),
}
</script>

<style scoped lang="scss">

</style>
