<template>
	<div class="number-circles">

		<div class="number-circles__groups">
			<div class="number-circles__group" v-for="(item, key) of groups" :key="key">
				<div class="number-circles__title">{{ item.title }}</div>
				<div class="number-circles__circles">
					<div class="number-circles__circle" v-for="(number, k) of item.numbers" :key="k">
						<div class="number-circles__circle-label" v-html="number.label"></div>
						<div class="number-circles__circle-value"><counter :number="number.value" :options="number.options" /></div>
					</div>
				</div>
			</div>
		</div>

	</div>
</template>

<script>
import Counter from '@/components/Counter'

export default {
	name: 'NumberCircles',
	components: {
		Counter,
	},
	props: {
		title: String,
		unit: String,
		numbers: Array,
		groups: Array,
	},
	mounted() {
		setTimeout(() => {
			this.$el.classList.add('viewed')
		}, 50)
	},
	methods: {
		format(value, options = {}) {
			return (options.prefix || '') + (new Intl.NumberFormat().format(value))
		},
	},
}
</script>

<style scoped lang="scss">
.number-circles {
	&__titles {
		display: flex;
		flex-direction: row;
	}

	&__groups {
		align-items: flex-start;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;

		@include media-query-min-width('tablet') {
			flex-direction: row;
			zoom: 90%;
		}

		@include media-query-min-width('desktop') {
			zoom: 100%;
		}
	}

	&__group {
		align-items: center;
		display: flex;
		flex-direction: column;
		flex: 1;
		justify-content: space-between;
	}

	&__circles {
		//@include gutter('padding', .75);
		align-items: center;
		display: flex;
		flex: 1;
		flex-direction: row;
		justify-content: space-between;

		@include media-query-min-width('tablet') {
			flex-direction: row;
		}

		@include media-query-min-width('desktop') {
			@include gutter('padding', .75);
			//width: 90%;
		}
	}

	&__circle {
		$circleSize: 168px;
		@include gutter('margin', .5);
		align-items: center;
		display: flex;
		flex-direction: column;
		height: 100%;
		justify-content: center;
		height: $circleSize;
		position: relative;
		width: $circleSize;

		@include media-query-min-width('tablet') {
			$circleSize: 256px;
			height: $circleSize;
			width: $circleSize;
		}

		&:before {
			content: '';
			position: absolute;
			bottom: 0;
			left: 0;
			right: 0;
			top: 0;
			border: 10px solid orange;
			border-radius: 50%;
		}

		&-label {
			@include fontSize(20px);
			font-family: $font-family-secondary;
			letter-spacing: 1px;
			font-weight: 200;
			color: color_('text', 'light');
			text-transform: uppercase;
			margin: 0;
			text-align: center;
			width: 100%;
			display: block;
			z-index: 6;

			@include media-query-min-width('tablet') {
				@include fontSize(20px);
			}
		}

		&-value {
			@include fontSize(40px);
			@include gutter('margin-bottom', .75);
			font-family: $font-family-secondary;
			letter-spacing: 1px;
			font-weight: bold;
			color: color_('text');
			text-transform: uppercase;
			margin: 0;
			text-align: center;
			width: 100%;
			display: block;
			z-index: 6;

			@include media-query-min-width('tablet') {
				@include fontSize(72px);
			}
		}
	}

	&__title {
		@include fontSize(48px);
		@include gutter('margin-bottom', .5);
		@include gutter('padding', .5);
		color: orange;
		font-family: $font-family-secondary;
		font-weight: bold;
		margin-top: 0;
		text-transform: uppercase;
		line-height: .9;
		text-align: center;


		@include media-query-min-width('tablet') {
			@include fontSize(48px);
		}
	}
}
</style>
