<template>
	<span class="counter">
	</span>
</template>

<script>
import { CountUp } from 'countup.js'

export default {
	name: 'Counter',
	props: {
		number: {
			type: Number,
			default: 0,
		},
		options: {
			type: Object,
			default: () => ({}),
		},
	},
	data: () => ({
		count: null,
		defaultOptions: {
			duration: 3,
			useGrouping: true,
			separator: ',',
			decimal: '.',
		},
	}),
	mounted() {
		const opts = Object.assign(this.defaultOptions, this.options)
		this.count = new CountUp(this.$el, Number(this.number), opts)
		if (this.count) {
			window.addEventListener('smoothScroll', this.onScroll)
		}
		this.count.start(() => {
			window.removeEventListener('smoothScroll', this.onScroll)
		})
	},
	destroyed() {
		if (this.count) {
			window.removeEventListener('smoothScroll', this.onScroll)
		}
	},
	methods: {
		onScroll(evt) {
			const elBounds = this.$el.getBoundingClientRect()
			if (elBounds.left < evt.detail.bounds.width && elBounds.right >= 0) {
				if (!this.count.error) {
					setTimeout(() => {
						this.count.start(() => {
							window.removeEventListener('smoothScroll', this.onScroll)
						})
					}, 500)
				}
				// el.classList.add('in-viewport')
				// el.classList.add('viewed')
			// } else if (elBounds.right < 0) {
				// el.classList.remove('in-viewport')
			}

		},
	},
}
</script>

<style scoped lang="scss">

</style>
